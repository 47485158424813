import * as NavigationMenu from '@radix-ui/react-navigation-menu';
import classNames from 'classnames';
import { ForwardedRef, forwardRef } from 'react';
import { NavigationBarProps } from 'src/features/layout/navigation-bar/NavigationBar';
import { Link } from 'src/features/shared/components/link';
import styles from '../MobileNavigationBar.module.scss';
import { usePathname } from 'next/navigation';

interface MobileNavigationBarProps extends NavigationBarProps {
  onClose: () => void;
}

export const MobileMenu = forwardRef(function MobileMenu(
  { menus, onClose }: MobileNavigationBarProps,
  ref: ForwardedRef<HTMLDivElement>,
) {
  const { headerPrimaryMenu, headerSecondaryMenu } = menus;
  const pathname = usePathname();
  return (
    <div className={styles.openMenuBar} ref={ref}>
      <ul className={styles.menuList}>
        {headerPrimaryMenu.items.map((item, index) => (
          <li
            key={index}
            className={classNames(styles.listItem, styles.primary)}
          >
            <NavigationMenu.Link asChild>
              <Link
                onClick={onClose}
                href={item.link?.href || `/${item.link?.slug}`}
                className={classNames(styles.linkItem, {
                  [styles.active]: pathname?.includes(
                    item.link?.href || item.link?.slug || '',
                  ),
                })}
              >
                {item.link?.label}
              </Link>
            </NavigationMenu.Link>
          </li>
        ))}
        {headerSecondaryMenu.items.map((item, index) => (
          <li
            key={index}
            className={classNames(styles.listItem, styles.secondary, {
              [styles.firstItem]: index === 0,
            })}
          >
            <NavigationMenu.Link asChild>
              <Link
                onClick={onClose}
                href={item.link?.href || `/${item.link?.slug}`}
                className={classNames(styles.linkItem, {
                  [styles.active]: pathname?.includes(
                    item.link?.href || item.link?.slug || '',
                  ),
                })}
              >
                {item.link?.label}
              </Link>
            </NavigationMenu.Link>
          </li>
        ))}
      </ul>
    </div>
  );
});
