import * as NavigationMenu from '@radix-ui/react-navigation-menu';
import classNames from 'classnames';
import Image from 'next/image';
import Link from 'next/link';
import { useEffect, useRef, useState } from 'react';
import theme from 'src/config/theme';
import { MobileMenu } from 'src/features/layout/navigation-bar/mobile/menu';
import { NavigationBarProps } from 'src/features/layout/navigation-bar/NavigationBar';
import { Button } from 'src/features/shared/components/button';
import Icons from 'src/features/shared/components/icons';
import { JoinButton } from 'src/features/shared/components/join-button';
import { ButtonVariant } from 'src/features/shared/types/enums/ButtonVariant';
import styles from './MobileNavigationBar.module.scss';

interface MobileNavigationBarProps extends NavigationBarProps {
  className: string;
  isTopBannerVisible: boolean;
}

export function MobileNavigationBar({
  className: givenClassName,
  clubId,
  textColor,
  background,
  menus,
  isTopBannerVisible,
}: MobileNavigationBarProps) {
  const [isOpen, setIsOpen] = useState(false);

  const contentRef = useRef<HTMLDivElement>(null);
  const menuRef = useRef<HTMLDivElement>(null);

  const getLogoImage = () => {
    if (textColor === 'white' && !isOpen) {
      return theme.logo.desktopLight;
    }
    if (background === 'yellow' && !isOpen) {
      return theme.logo.desktopWhiteDot;
    }
    return theme.logo.desktop;
  };

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    }

    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [isOpen]);

  useEffect(() => {
    const setPadding = () => {
      const bottom = contentRef.current?.getBoundingClientRect()?.bottom ?? 0;
      menuRef.current?.style.setProperty('padding-top', `${bottom}px`);
    };
    window.addEventListener('scroll', setPadding, {
      passive: true,
    });
    window.addEventListener('resize', setPadding, {
      passive: true,
    });
    setPadding();

    return () => {
      window.removeEventListener('scroll', setPadding);
      window.removeEventListener('resize', setPadding);
    };
  }, [isTopBannerVisible]);

  return (
    <div
      ref={contentRef}
      className={classNames(givenClassName, styles.contentMobile)}
    >
      <NavigationMenu.Link asChild>
        <Link href="/" className={styles.logoLink}>
          <Image
            alt={`${theme.name} logo`}
            src={getLogoImage()}
            width={156}
            height={24}
          />
        </Link>
      </NavigationMenu.Link>
      <div className={styles.rightNavContainer}>
        <div
          className={classNames(styles.join, {
            [styles.black]: background === 'yellow',
            [styles.isOpen]: isOpen,
          })}
        >
          <JoinButton clubId={clubId} />
        </div>
        <NavigationMenu.Item className={styles.navigationItem}>
          <Button
            variant={ButtonVariant.Link}
            className={classNames(styles.toggle, { [styles.isOpen]: isOpen })}
            onClick={() => setIsOpen(!isOpen)}
            data-testid="menu-button"
          >
            {isOpen ? <Icons.CloseIcon /> : <Icons.Menu />}
          </Button>
          <div
            data-testid="menu-list"
            className={classNames(styles.menuListContent, {
              [styles.open]: isOpen,
            })}
          >
            <MobileMenu
              ref={menuRef}
              menus={menus}
              clubId={clubId}
              onClose={() => setIsOpen(false)}
              background={background}
            />
          </div>
        </NavigationMenu.Item>
      </div>
    </div>
  );
}
