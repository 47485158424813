import classNames from 'classnames';
import { ComponentProps } from 'react';
import {
  SECTIONS_BG_COLORS,
  SectionBackgroundColor,
} from 'src/features/shared/utils/constants';
import styles from './Section.module.scss';
import DecoratedBorderBottom from './assets/decorated-border-bottom.svg';
import DecoratedBorderTop from './assets/decorated-border-top.svg';

export interface ISectionProps extends ComponentProps<'section'> {
  hasNoBottomMargin?: boolean;
  hasImage?: boolean;
  forceMobileView?: boolean;
  backgroundColor?: SectionBackgroundColor | null;
  decoratedBorderTop?: boolean;
  decoratedBorderBottom?: boolean;
}

export const Section = ({
  children,
  className,
  hasNoBottomMargin = false,
  hasImage = false,
  forceMobileView,
  backgroundColor,
  decoratedBorderTop,
  decoratedBorderBottom,
  ...otherProps
}: ISectionProps) => {
  return (
    <>
      <section
        className={classNames(
          styles.section,
          {
            [styles['no-bottom-margin']]: hasNoBottomMargin,
            [styles['no-image']]: !hasImage,
            [styles.bgSoftPeach]:
              backgroundColor === SECTIONS_BG_COLORS.softPeach,
            [styles.bgSoftSand]:
              backgroundColor === SECTIONS_BG_COLORS.softSand,
            [styles.bgSoftYellow]:
              backgroundColor === SECTIONS_BG_COLORS.softYellow,
          },
          className,
        )}
        {...otherProps}
      >
        {decoratedBorderTop && (
          <DecoratedBorderTop
            className={classNames(styles.decoratedBorder, styles.top)}
            width={undefined}
            height={undefined}
          />
        )}
        <div
          className={classNames(
            styles.sectionContent,
            forceMobileView && styles.forceMobileView,
            {
              [styles.hasBackground]: !!backgroundColor,
            },
          )}
        >
          {children}
        </div>

        {decoratedBorderBottom && (
          <DecoratedBorderBottom
            className={classNames(styles.decoratedBorder, styles.bottom)}
            width={undefined}
            height={undefined}
          />
        )}
      </section>
    </>
  );
};
