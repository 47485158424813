import styles from './ClubDetails.module.scss';
import { ITag } from '@features/club-page/utils/use-anchor-links-tags';
import { Layout, Section } from '@features/shared/components/layout';
import { CurlyLine } from '../../../shared/components/curly-line';
import { IClubContentPage } from 'src/features/shared/contentful/types/IClubContentPage';
import { Club } from 'src/features/shared/types/Club';
import { AboutClub } from './about-club';
import { AnchorLinks } from './anchor-links';
import { ClubPageHeader } from './club-page-header';
import { OpeningHours } from './opening-hours';

export interface IClubDetailsProps {
  club: Club.Details;
  isPanel: boolean;
  tags: ITag[];
  isMobile: boolean;
  hasClubOffers: boolean;
  onClickGallery: null | ((e: React.MouseEvent) => void);
  page: IClubContentPage;
}

export const ClubDetails = ({
  club,
  isPanel,
  tags,
  onClickGallery,
  isMobile,
  hasClubOffers,
  page,
}: IClubDetailsProps) => {
  const clubWithOpeningHours = {
    ...club,
    openingHours: club.openingHours || page.openingHours,
    openingHoursExceptions:
      club.openingHoursExceptions || page.openingHoursExceptions,
  };

  return (
    <Section forceMobileView={isPanel} className={styles.section}>
      <ClubPageHeader
        club={clubWithOpeningHours}
        media={page.clubHeaderMedia}
        isMobile={isMobile}
        isPanel={isPanel}
        onClickGallery={onClickGallery}
      />

      <AnchorLinks tags={tags} isPanel={isPanel} />

      <Layout.Container forceMobileView={isPanel}>
        <Layout.Row>
          {page.intro ? (
            <Layout.Col columns={isPanel ? 'sm:12' : 'sm:6'}>
              <AboutClub
                description={page.intro}
                additionalDescription={page.description}
                highlightedAttributes={page.highlightedAttributes}
                hasClubOffers={hasClubOffers}
                isPanel={isPanel}
              />
            </Layout.Col>
          ) : null}

          {clubWithOpeningHours.openingHours ? (
            <Layout.Col
              columns={isPanel ? 'sm:12' : 'lg:4 sm:5'}
              offset={isPanel ? '' : 'lg:2 sm:1'}
            >
              <OpeningHours club={clubWithOpeningHours} isPanel={isPanel} />
            </Layout.Col>
          ) : null}
        </Layout.Row>
      </Layout.Container>

      <CurlyLine />
    </Section>
  );
};
