import * as NavigationMenu from '@radix-ui/react-navigation-menu';
import classNames from 'classnames';
import { useIntl } from 'domains/i18n';
import Icons from 'features/shared/components/icons';
import Image from 'next/image';
import theme from 'src/config/theme';
import { NavigationBarProps } from 'src/features/layout/navigation-bar/NavigationBar';
import { JoinButton } from 'src/features/shared/components/join-button';
import { Link } from 'src/features/shared/components/link';
import { ButtonVariant } from 'src/features/shared/types/enums/ButtonVariant';
import styles from './DesktopNavigationBar.module.scss';
import { usePathname } from 'next/navigation';

interface DesktopNavigationBarProps extends NavigationBarProps {
  className: string;
}

export function DesktopNavigationBar({
  menus,
  clubId,
  background,
  textColor,
  className,
}: DesktopNavigationBarProps) {
  const { headerPrimaryMenu, headerSecondaryMenu } = menus;
  const { formatMessage } = useIntl();

  const pathname = usePathname();

  const logoImage =
    textColor === 'white'
      ? theme.logo.desktopLight
      : background === 'yellow'
      ? theme.logo.desktopWhiteDot
      : theme.logo.desktop;

  return (
    <div className={className}>
      <NavigationMenu.Link asChild>
        <Link href="/" className={styles.link}>
          <Image
            className={classNames(styles.logo)}
            alt={`${theme.name} logo`}
            src={logoImage}
            width={190}
            height={32}
          />
        </Link>
      </NavigationMenu.Link>
      <NavigationMenu.List className={styles.primaryMenuList}>
        {headerPrimaryMenu.items.map((item) => (
          <NavigationMenu.Item
            className={styles.listItem}
            key={item.link?.href}
          >
            <NavigationMenu.Link
              className={classNames(styles.linkItem, {
                [styles.active]: pathname?.includes(
                  item.link?.href || item.link?.slug || '',
                ),
              })}
              href={item.link?.href || `/${item.link?.slug}`}
            >
              {item.link?.label}
            </NavigationMenu.Link>
          </NavigationMenu.Item>
        ))}
        <NavigationMenu.Item>
          <NavigationMenu.Trigger
            onPointerEnter={(event) => event.preventDefault()}
            onPointerMove={(event) => event.preventDefault()}
            onPointerLeave={(event) => event.preventDefault()}
            className={classNames(styles.more, styles.linkItem)}
          >
            <span>{formatMessage('more')}</span>
            <Icons.CaretDown />
          </NavigationMenu.Trigger>
          <NavigationMenu.Content
            data-orientation="vertical"
            onPointerEnter={(event) => event.preventDefault()}
            onPointerMove={(event) => event.preventDefault()}
            onPointerLeave={(event) => event.preventDefault()}
          >
            <div className={styles.secondaryMenuWrapper}>
              <ul className={styles.secondaryMenuList}>
                {headerSecondaryMenu.items.map((item, index) => (
                  <li key={index} className={styles.secondaryListItem}>
                    <NavigationMenu.Link asChild>
                      <Link
                        href={item.link?.href || `/${item.link?.slug}`}
                        className={classNames(styles.linkItem, {
                          [styles.active]: pathname?.includes(
                            item.link?.href || item.link?.slug || '',
                          ),
                        })}
                      >
                        {item.link?.label}
                      </Link>
                    </NavigationMenu.Link>
                  </li>
                ))}
              </ul>
            </div>
          </NavigationMenu.Content>
        </NavigationMenu.Item>
      </NavigationMenu.List>
      <div className={styles.rightNavContainer}>
        <div className={styles.joinWrapper}>
          <JoinButton
            variant={
              background === 'yellow'
                ? ButtonVariant.Secondary
                : ButtonVariant.Primary
            }
            clubId={clubId}
            dataTestId='nav-bar-become-member-button'
          />
        </div>
      </div>
    </div>
  );
}
