'use client';
import { disableDraftMode } from 'src/services/proxy';
import styles from './DraftModeToolbar.module.scss';
import { useRouter } from 'next/navigation';

export function DraftModeToolbar() {
  const router = useRouter();

  const onDisable = async () => {
    await disableDraftMode();
    router.refresh();
  };

  return (
    <div className={styles.container}>
      Preview mode is enabled
      <button className={styles.button} onClick={onDisable}>
        Disable
      </button>
    </div>
  );
}
