import { Image } from '@features/pg-funnel/services/contentful/types';
import { ContentSections } from '@features/shared/contentful/types/IContentSection';
import { IImageCarouselSection } from '@features/shared/contentful/types/IImageCarouselSection';
import { ITextImageSection } from 'src/features/shared/contentful/types/ITextImage';
import { IImageSliderSection } from '@features/shared/contentful/types/IImageSliderSection';
import { removeDuplicateObjects } from 'src/features/shared/utils/removeDuplicatedItems';

/**
 * This hook will collect all images from specifically chosen content sections
 * Additionally the main image of the club page is added as the first image
 */
export function getAllClubImages(
  mainImage?: Image,
  contentSections?: ContentSections,
) {
  if (!contentSections || !mainImage) {
    return {
      images: mainImage ? [mainImage] : [],
      isSliderOrCarouselActive: false,
    };
  }

  // Image sliders
  const imageSliders = contentSections.filter(
    (section) => section.type === 'imageSliderSection',
  ) as IImageSliderSection[];
  const imageSliderImages = imageSliders
    .map((imageSlider) => {
      const result: Image[] = [];

      /**
       * Add primary and secondary images to an array and keep the order
       * e.g.: [primary, secondary, primary, secondary]
       */
      imageSlider.items
        .map((item) => ({
          primary: item.primaryImage,
          secondary: item.secondaryImageLarge,
        }))
        .map((value) => {
          result.push(value.primary);
          result.push(value.secondary);
        });

      return result;
    })
    .reduce((acc, curr) => acc.concat(curr), []);

  // image carousel section
  const imageCarousels = contentSections.filter(
    (block) => block.type === 'imageCarouselSection',
  ) as IImageCarouselSection[];
  const imageCarouselImages = imageCarousels
    .map((imageCarousel) => imageCarousel.items.map((item) => item.image))
    .reduce((acc, curr) => acc.concat(curr), []);

  // Image section
  const imageSections = contentSections.filter(
    (block) => block.type === 'imageSection',
  ) as ITextImageSection[];
  const imageSectionImages = imageSections
    .map((imageSection) => imageSection.image)
    .filter((entry): entry is Image => entry !== null);

  const images = [
    mainImage,
    ...imageSliderImages,
    ...imageCarouselImages,
    ...imageSectionImages,
  ];

  return {
    isSliderOrCarouselActive:
      imageCarousels.length > 0 || imageSliders.length > 0,
    images: removeDuplicateObjects(images, 'src'),
  };
}
