import styles from './SectionButton.module.scss';
import Icons from 'features/shared/components/icons';
import { Button } from 'src/features/shared/components/button';
import { ILink } from 'src/features/shared/contentful/types/ILink';
import { ButtonVariant } from 'src/features/shared/types/enums/ButtonVariant';
import { ButtonDisplayAs } from 'src/features/shared/utils/constants';
import { GA4_EVENTS, pushGA4Event } from 'src/features/shared/utils/ga4';
import classNames from 'classnames';

export const SectionButton = ({
  link,
  displayAs,
  label,
  eventName,
}: {
  link: ILink;
  displayAs: ButtonDisplayAs;
  label: string;
  eventName?: string;
}) => {
  const getButtonVariant = () => {
    switch (displayAs) {
      case ButtonDisplayAs.PrimaryButton:
        return ButtonVariant.Primary;
      case ButtonDisplayAs.SecondaryButton:
        return ButtonVariant.Secondary;
      case ButtonDisplayAs.TertiaryButton:
        return ButtonVariant.Ghost;
      case ButtonDisplayAs.Link:
        return ButtonVariant.Link;
    }
  };
  const aditionalProps =
    displayAs === ButtonDisplayAs.Link
      ? {
          isUnderlined: true,
          icon: <Icons.ArrowRight />,
        }
      : null;

  const className = classNames(styles.sectionButton, {
    [styles.textLink]: displayAs === ButtonDisplayAs.Link,
  });

  return (
    <Button
      onClick={() => {
        eventName &&
          pushGA4Event(GA4_EVENTS.trackEvent, {
            event_name: eventName,
          });
      }}
      className={className}
      variant={getButtonVariant()}
      href={link.externalLink?.link || link.slug || link.href}
      openInNewTab={link.externalLink?.openInNewTab || link.openInNewTab}
      {...aditionalProps}
    >
      {label}
    </Button>
  );
};
