import classNames from 'classnames';
import NextLink from 'next/link';

import { ComponentProps, ForwardedRef, forwardRef } from 'react';
import { UrlObject } from 'url';
import styles from './Link.module.scss';

interface BaseProps {
  openInNewTab?: boolean;
  isSmall?: boolean;
  dataTestId?: string;
  icon?: SVGComponent;
  href: string | UrlObject;
}

interface NextLinkProps extends BaseProps, ComponentProps<typeof NextLink> {
  isHtmlTag?: false;
}
interface AnchorTagProps extends BaseProps, Omit<ComponentProps<'a'>, 'href'> {
  isHtmlTag: true;
}

type ILinkProps = NextLinkProps | AnchorTagProps;

const Link = forwardRef<HTMLAnchorElement, ILinkProps>(function Link(
  {
    className,
    openInNewTab,
    isSmall,
    dataTestId,
    children,
    icon: Icon,
    ...props
  }: ILinkProps,
  ref: ForwardedRef<HTMLAnchorElement>,
) {
  const linkClassName = classNames(
    styles.link,
    {
      [styles.withIcon]: Icon,
      [styles.small]: isSmall,
    },
    className,
  );

  if (props.isHtmlTag) {
    return (
      <a
        target={openInNewTab ? '_blank' : '_self'}
        rel={openInNewTab ? 'noreferrer' : undefined}
        data-testid={dataTestId || null}
        className={linkClassName}
        {...(props as AnchorTagProps)}
        href={props.href as string}
        ref={ref}
      >
        {children}
        {Icon && <Icon />}
      </a>
    );
  }

  return (
    <NextLink
      target={openInNewTab ? '_blank' : '_self'}
      data-testid={dataTestId || null}
      className={linkClassName}
      prefetch={false}
      ref={ref}
      {...props}
    >
      {children}
      {Icon && <Icon />}
    </NextLink>
  );
});

export { Link };
