'use client';
import { useIntl } from 'domains/i18n';
import { ErrorProps } from 'next/error';
import { useRouter } from 'next/navigation';
import { GenericError } from 'src/features/error-page/components/generic-error';
import { Footer } from 'src/features/layout/footer/components';
import { IMappedMenus } from 'src/features/layout/menu/types/IMappedMenus';
import { Error404Page } from '../error-404-page';
import { NavigationBar } from 'src/features/layout/navigation-bar';

export interface IErrorHandlerProps extends ErrorProps {
  headerMenus: IMappedMenus;
}

export function ErrorHandler({ statusCode, headerMenus }: IErrorHandlerProps) {
  const router = useRouter();
  const isError404 = statusCode === 404;
  const { formatMessage } = useIntl();

  return (
    <>
      <NavigationBar menus={headerMenus} />
      {isError404 ? (
        <Error404Page />
      ) : (
        <GenericError
          title={formatMessage('error-500-page.title')}
          description={formatMessage('error-500-page.description')}
          buttonLabel={formatMessage('error-500-page.reload-label')}
          onClick={() => router.refresh()}
        />
      )}
      <Footer />
    </>
  );
}
